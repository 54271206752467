import React, { useState } from 'react'
import { Grid, Button, Box, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getAllTeams, deleteTeam } from '../../../services/Api';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";


export default function TeamsList() {
  const [teamList, setTeamList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const handleClose = () => {
    setOpenModal(false);
    fetchData()
  };

  const columns = [
    {
      field: "index",
      headerName: "Nr.",
      width: 70,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    { field: "name", headerName: "Emri", width: 200 },
    {
      field: "sport",
      headerName: "Lloji i Sportit",
      width: 200,
    },
    {
      field: "authorName",
      headerName: "Autori",
      width: 200,
    },
    {
      field: "year",
      headerName: "Viti i krijimit",
      width: 250,
    },
    {
      field: "actions",
      headerName: "Veprimet",
      sortable: false,
      width: 450,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              color="error"
              disabled={params.row.tsDeleted}
              onClick={() => deleteTeamReq(params.row.id)}
            >
              Fshij skuadren
            </Button>
          </>
        );
      },
    },
  ];

  const fetchData = async () => {
    setLoading(true);

    await getAllTeams()
      .then(data => {
        setTeamList(data.data.results);
        setLoading(false)
      })
      .catch(err => {
        setLoading(false);
        console.log(err)
      });
  }

  const deleteTeamReq = async (id) => {
    const result = window.confirm("Jeni te sigurt qe doni te vazhdoni?");
    if (!result) return;
    setLoading(true);

    const response = await deleteTeam(id);

    if (response.success) {
      setOpenModal(true);
      setLoading(false);
    } else {
      setLoading(false);
      alert(`Ndodhi nje error me fshirjen e skuadres, ${response.message}`)
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [])

  return (
    <>
      <Grid
        container
        justifyContent="center"
        sx={{
          minHeight: "calc(100vh - 48px)",
        }}
      >

        <Grid item xs={12}>
          {loading &&
            <Box sx={{ display: 'flex', position: "absolute", top: " 50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: "100" }}>
              <CircularProgress size={50} />
            </Box>
          }

          <DataGrid
            sx={{
              "&.MuiDataGrid-root": {
                ".MuiDataGrid-columnHeaderTitleContainer": {
                  color: "#08C47C",
                  fontSize: "1.1rem",
                },
              },
            }}
            rows={teamList}
            columns={columns}
            pageSize={80}
            rowsPerPageOptions={[5]}
          />
        </Grid>
      </Grid>

      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Skuadra u fshi me sukses.</DialogTitle>
        <Button onClick={handleClose} >
          Mbyll
        </Button>
      </Dialog>
    </>
  );
}
