// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-version-container {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: column;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
}

h2 {
    margin-top: 40px;
    margin-bottom: 0px;
}

table {
    margin-top: 40px;
    border: 1px solid green;
    border-spacing: 0;
}

table td {
    padding: 10px;
}

table thead {
    background-color: #08C47C;
}

table tbody {
    border-top: 1px solid #08C47C;
}

input::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: red;
    bottom: 0;
    z-index: 0;
}

input:invalid::after {
    z-index: 10;
}

@media (max-width: 768px) {
    .app-version-container {
        width: 85%;
    }
    table td {
        display: block;
        border-bottom: 1px solid gray;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/dashboard/components/appversion.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,WAAW;IACX,WAAW;IACX,qBAAqB;IACrB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,cAAc;QACd,6BAA6B;IACjC;AACJ","sourcesContent":[".app-version-container {\n    display: flex;\n    justify-content: center;\n    align-content: center;\n    flex-flow: column;\n    text-align: center;\n    margin-left: auto;\n    margin-right: auto;\n    width: 65%;\n}\n\nh2 {\n    margin-top: 40px;\n    margin-bottom: 0px;\n}\n\ntable {\n    margin-top: 40px;\n    border: 1px solid green;\n    border-spacing: 0;\n}\n\ntable td {\n    padding: 10px;\n}\n\ntable thead {\n    background-color: #08C47C;\n}\n\ntable tbody {\n    border-top: 1px solid #08C47C;\n}\n\ninput::after {\n    content: \"\";\n    position: absolute;\n    left: 0;\n    width: 100%;\n    height: 2px;\n    background-color: red;\n    bottom: 0;\n    z-index: 0;\n}\n\ninput:invalid::after {\n    z-index: 10;\n}\n\n@media (max-width: 768px) {\n    .app-version-container {\n        width: 85%;\n    }\n    table td {\n        display: block;\n        border-bottom: 1px solid gray;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
