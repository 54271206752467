// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Business_notes__pVCmV {
    height: 150px;
    width: 98%;

}

.Business_notes__pVCmV:focus {
    outline: none !important;
    border:2px solid #06c47c;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/dashboard/components/Business.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;;AAEd;;AAEA;IACI,wBAAwB;IACxB,wBAAwB;AAC5B","sourcesContent":[".notes {\n    height: 150px;\n    width: 98%;\n\n}\n\n.notes:focus {\n    outline: none !important;\n    border:2px solid #06c47c;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notes": `Business_notes__pVCmV`
};
export default ___CSS_LOADER_EXPORT___;
