import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Loader from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import { logout } from "../../features/user/userSlice";

export default function Navbar() {
  const isLoading = useSelector((state) => state.user.isLoading);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()

  const handleLogout = () => {
    dispatch(logout());
    localStorage.clear();
    navigate("/");
  };

  return (
    <Box sx={{
       flexGrow: 1, 
       ...(location.pathname.includes('mobile-profile') && {
        display:"none"
    }) 
    }}>
      <AppBar position="static">
        <Toolbar
          variant="dense"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <div
            onClick={() => navigate("/dashboard")}
            style={{
              cursor: "pointer",
            }}
          >
            <img src={"/logo-app.png"} width={170} />
          </div>
          {user && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  color: "white",
                  marginRight: "10px",
                  cursor: "pointer",
                  fontWeight: 600,
                  fontSize: "1.1em",
                }}
                onClick={handleLogout}
              >
             {user.name}  | Dil
              </span>
              <LoginIcon sx={{ fill: "white" }} />
            </div>
          )}
        </Toolbar>
      </AppBar>

      {isLoading && <Loader />}
    </Box>
  );
}
