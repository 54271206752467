import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Grid,
  Button,
  Box,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,

} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axiosInstance from "../../../../common/helpers/axios.instance";
import { useDispatch } from "react-redux";
import { finishLoader, startLoader } from "../../../../features/user/userSlice";
import "./appversion.css";

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export default function AppVersion() {
  const [showModal, setShowModal] = React.useState(false);
  const [appVersion, setAppVersion] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const versionRef = React.useRef("");
  const buildNumberRef = React.useRef("");

  const fetchData = async () => {
    try {
      await axiosInstance
        .get(process.env.REACT_APP_BASE_API + "/appversioncheck")
        .then(res => {
          console.log(res.data.data);
          setAppVersion(res.data.data)
        });
    } catch (error) {
      console.log({ error });
    }
  }

  React.useEffect(() => {
    fetchData();
  }, [])

  const handleChange = () => {
    setShowModal(true);
  }

  const submitNewAppVersion = async () => {
    setLoading(true);

    let newVersion = {
      id: appVersion.id,
      version: versionRef.current.value,
      buildNumber: parseInt(buildNumberRef.current.value)
    }

    try {
      await axiosInstance
        .post(process.env.REACT_APP_BASE_API + "/appversioncheck", newVersion)
        .then(() => {
          setLoading(false);
          setShowModal(false);
          setAppVersion(newVersion);
        })
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  }

  const styles = {
    input: {
      "&:invalid": {
        border: "red solid 2px"
      }
    }
  };

  return (
    <div className="app-version-container">
      <h2>Versioni Aktual i Aplikacionit Sportisti</h2>
      <p>Kliko mbi buttoin Ndrysho per te bere ndryshimin e versionit te app, pasi te jete publikuar ne appstore dhe playstore.
         Perdoruesit do te njoftohen nepermjet popop per te bere update applikacionin.</p>
      <table>
        <thead>
          <tr>
            <td>Versioni</td>
            <td>BuildNumber</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{appVersion?.version}</td>
            <td>{appVersion?.buildNumber}</td>
            <td>
              <Button variant="contained" onClick={() => handleChange()}>Ndrysho</Button></td>
          </tr>
        </tbody>
      </table>

      {showModal &&
        <Dialog fullWidth={false} open={true}>
          <DialogTitle color="primary">Updato Versionin!</DialogTitle>

        {loading && <Stack sx={{ color: 'grey.500' }} spacing={2} justifyContent="center" direction="row">
            <CircularProgress color="success" />
          </Stack>
        }
          
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box marginTop={1}>
                      <TextField label="App Version"
                        inputRef={versionRef}
                        name="name" fullWidth
                        type="text"
                        inputProps={{ pattern: "[0-9\.]*$" }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box marginY={1}>
                      <TextField
                        type="number"
                        label="App BuildNumber"
                        inputRef={buildNumberRef}
                        name="app-buildnumber"
                        fullWidth
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModal(false)} variant="contained" color="error">
              Mbyll
            </Button>
            <Button variant="contained" color="primary" onClick={() => submitNewAppVersion()}>
              <span style={{ color: "white" }}>Ruaj</span>
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
}