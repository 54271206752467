import { useMemo } from "react";
import appstore from "../../../assets/app-store.png"
import playstore from "../../../assets/playstore.png"
import { useParams } from 'react-router-dom';

 const MobileAppRouter = () => {

    let { id } = useParams();

    useMemo(() => {
        const anchor = document.createElement("a")
        anchor.setAttribute('href', `sportisti://?id=${id}`);
        document.getElementsByTagName('body')[0].appendChild(anchor);
        anchor.click()
    }, [1])

    const handleClick = (link) => {
        window.open(link)
    }
   
    return <div style={{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center",
        height:"100vh",
        background:'rgb(8, 196, 124)',
        
    }}>
        <div style={{marginBottom:"40px"}}>
            <img src="/logo-app.png"></img>
        </div>
        <div>
            <div style={{color:"white", fontWeight:"600"}}>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}
                 onClick={()=>handleClick("https://play.google.com/store/apps/details?id=com.rubiktech.csoa")}>
                    <img src={playstore} height="30" style={{marginRight:"1rem"}}/>
                    <p>Shkarkoni Sportisti Android</p>
                </div>
             
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}} 
                onClick={()=>handleClick("https://apps.apple.com/al/app/sportisti/id6446689811")}
                >
                 <img src={appstore} height="30" style={{marginRight:"1rem"}}/>
                 <p>Shkarkoni Sportisti IOS</p>
                </div>
            </div>
        </div>
      
    </div>
}

export default MobileAppRouter