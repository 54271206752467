
import axiosInstance from "../../common/helpers/axios.instance";

export default class ApiService {
    async postData(path, data) {

    }

    async getData(path, data) {
        const url = process.env.REACT_APP_BASE_API + path;
        return await axiosInstance.get(url).then((result) => {
            return result.data;
        }).catch((err) => {
            console.log(err);
        });
    }

    async deleteTeam(path) {
        const url = process.env.REACT_APP_BASE_API + path;
        console.log("delete ", url);

        try {
            const result = await axiosInstance.delete(url);
            return result.data;
            
        } catch (err) {
            console.error("Error occurred during delete request:", err);

            return {
                success: false,
                message: err.message,
                status: err.response?.status,
                data: err.response?.data || null,
            };
        }
    }
}
